import { request } from "@/api/service";
import store from "@/store";
import setting from "@/setting";
export default {
  code: "accountTypeChange",
  name: "收款账户选择修改",
  desc: "收款账户选择修改",
  func: async function() {
    if (!this.val || this.pageForm.account.accountName) {
      return false;
    }
    switch (this.val) {
      case "1": // 经销商
        // eslint-disable-next-line no-case-declarations
        const dealer = await request({
          url: "/organization/dealer/info/load",
          method: "post",
          data: {
            id: store.getters.info.orgId
          }
        });
        if (dealer && dealer.receiptList && dealer.receiptList.length) {
          this.pageForm.account.accountName = dealer.receiptList[0].accountName;
          this.pageForm.account.bankName = dealer.receiptList[0].secondBank;
          this.pageForm.account.bankCode = dealer.receiptList[0].secondAccount;
        }
        break;
      case "2": // 客户本人
        this.pageForm.account.accountName = null;
        this.pageForm.account.bankName = null;
        this.pageForm.account.bankCode = null;
        break;
      case "3": // 丰田金融
        this.pageForm.account.accountName = setting.toyota.accountName;
        this.pageForm.account.bankName = setting.toyota.bankName;
        this.pageForm.account.bankCode = setting.toyota.bankCode;
        break;
    }
  }
};
